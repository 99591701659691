import { memoize } from 'lodash-es'

/**
 * Used to "remove" color from tags/highlights
 */
export const defaultColor = 'var(--colors-tags-background)'
export const defaultTextColor = 'var(--colors-typography-notes)'
/**
 * Chooses the highest contrast based on W3C guidelines.
 * @param backgroundColor hex code string (i.e. `#024058`)
 * @returns `#000000` or `#ffffff`
 */
export const lightOrDarkForeground: (
  backgroundColor?: string,
) => '#000000' | '#ffffff' = memoize((backgroundColor) => {
  if (!backgroundColor) {
    return '#000000'
  }
  if (!/^#[0-9A-F]{6}$/i.test(backgroundColor)) {
    throw new Error(
      `Invalid color ${backgroundColor}! Must be a valid 7-character string specifying an RGB color in hexadecimal format.`,
    )
  }
  const color = backgroundColor.substring(1, 7) // remove `#`
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) =>
    col <= 0.03928 ? col / 12.92 : Math.pow((col + 0.055) / 1.055, 2.4),
  )
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? '#000000' : '#ffffff'
})
